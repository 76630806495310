import React, { useState } from "react";
import Layout from "../containers/Layout";
import { Contact } from "../components";
import {
  Jumbotron,
  Diff,
  Mobility,
  Measures,
  Application,
  Features,
  Powers,
  Bundles,
  Testimonials,
  Stickybar,
  WithReeddiCapsule,
  PowerDevice,
  Specifications,
} from "../components/ReeddiCapsuleComponents";
import { Cta } from "../components/AmbassadorComponents";
import Modallayout from "../containers/Modallayout";
import BuyNowModal from "../components/ReeddiCapsuleComponents/BuyNowModal";

export default function Reeddicapsule() {
  const [showBuyModal, setShowBuyModal] = useState(false);
  const handleShowModal = () => {
    setShowBuyModal(true);
  };

  return (
    <Layout
      title={"Reeddi Capsule"}
      stickyComponent={<Stickybar handleShowModal={handleShowModal} />}
    >
      <Jumbotron
        handleShowModal={handleShowModal}
        modalComponent={<Modallayout />}
      />
      <Diff />
      <Application />
      <Powers />
      <Measures />
      <Features />
      <PowerDevice modalComponent={<Modallayout />} />
      <Specifications />
      <WithReeddiCapsule />
      <Bundles />
      <Cta />
      <Contact />

      {/* Buy Now Modal */}
      <BuyNowModal
        showBuyModal={showBuyModal}
        handleClose={() => setShowBuyModal(false)}
      />
    </Layout>
  );
}
