import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { allMobility, energyPack, everytimeEnergy } from "./BundleData";
import BundleCard from "./BundleCard";

function AllMobility({ handleMouseOver }) {
  return (
    <div onMouseOver={() => handleMouseOver(allMobility[0])}>
      {allMobility.map((data) => {
        return <BundleCard key={data.bundleType} data={data} />;
      })}
    </div>
  );
}

// function EnergyPack({ handleMouseOver }) {
//   return (
//     <div onMouseOver={() => handleMouseOver(energyPack[0])}>
//       {energyPack.map((data) => {
//         return <BundleCard key={data.bundleType} data={data} />;
//       })}
//     </div>
//   );
// }

function EverytimeEnergy({ handleMouseOver }) {
  return (
    <div onMouseOver={() => handleMouseOver(everytimeEnergy[0])}>
      {everytimeEnergy.map((data) => {
        return <BundleCard key={data.bundleType} data={data} />;
      })}
    </div>
  );
}

export default function BuyNowModal({ showBuyModal, handleClose }) {
  const cancelButtonRef = useRef(null);

  const [selectedPrice, setSelectedPrice] = useState("229,000");

  const handleMouseOver = (data) => {
    console.log(data);
    setSelectedPrice(data?.price?.toLocaleString());
  };

  return (
    <Transition.Root show={showBuyModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-30 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => {
          handleClose(false);
        }}>
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 mt-16 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div className="inline-block w-full max-w-md align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle md:max-w-4xl">
              <div className="bg-[#ffffff] px-6 md:px-10 py-6 md:py-10">
                <div className="grid grid-cols-1 gap-y-16 md:grid-cols-2 gap-6 my-4">
                  <AllMobility handleMouseOver={handleMouseOver} />
                  <EverytimeEnergy handleMouseOver={handleMouseOver} />
                  {/* <EnergyPack handleMouseOver={handleMouseOver} /> */}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
